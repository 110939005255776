<template>
  <div>
    营销管理
  </div>
</template>

<script>
export default {
  name: 'Market'
}
</script>

<style scoped>

</style>
